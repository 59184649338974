<template>
    <div class="mobileLabelManage scrolling">
        <van-search @search="onSearch" v-model="queryParam.name" placeholder="请输入标签名称" />
        <div>
            <div class="item" v-for="tag in tags" :key="tag.id">
                <van-cell :title="tag.name" @click="handleClick(tag)" is-link>
                    <template #right-icon>
                        <van-icon :name="tag.unfold ? 'arrow-down' : 'arrow'" />
                    </template>
                </van-cell>
                <div class="persons" v-if="tag.unfold">
                    <div class="tagContainer" v-if="tag.persons && tag.persons.length > 0">
                        <a-tag
                            class="tags flex0"
                            color="blue"
                            closable
                            @close="delPerson(tag, person.id)"
                            :key="person.id"
                            v-for="person in tag.persons"
                        >
                            <OpenData
                                :type="getDataType(person)"
                                :openid="person.userId || person.deptId"
                                :corpid="person.corpId"
                            ></OpenData>
                        </a-tag>
                        <a-tag @click="toAddPerson(tag)">增加成员</a-tag>
                    </div>
                    <template v-else>
                        <div class="noPersonTip">
                            该标签下尚未拥有成员，请先<a-button
                                @click="toAddPerson(tag)"
                                style="padding-left: 0"
                                type="link"
                            >
                                选择成员
                            </a-button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="footer">
            <a-button class="addLabel" type="primary" @click="addLabelName" size="small">新建</a-button>
            <a-button @click="$router.push({ name: 'delectLabel' })" type="primary" size="small">管理</a-button>
        </div>
    </div>
</template>
<script>
import { delLablePerson, documentLablePage, getLablePersonList } from '@/api/modular/fileStream/documentLabelManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { initWx } from '../components/minxin'
export default {
    mixins: [initWx],
    components: {
        OpenData,
    },
    data() {
        return {
            queryParam: {},
            tags: [],
        }
    },
    mounted() {
        this.getTags()
    },
    methods: {
        onSearch() {
            this.getTags()
        },
        addLabelName() {
            this.$router.push({ name: 'addLabelName' })
        },
        getDataType(node) {
            return node.deptId ? 'departmentName' : 'userName'
        },
        handleClick(tag) {
            tag.unfold = !tag.unfold
            if (tag.persons === 0) {
                getLablePersonList({
                    labelId: tag.id,
                })
                    .then((res) => {
                        if (res.code === 200) {
                            tag.persons = res.data
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        tag.persons = []
                    })
            }
        },
        toAddPerson(tag) {
            this.$router.push({
                name: 'addLabelPerson',
                query: {
                    tag: encodeURIComponent(JSON.stringify(tag)),
                },
            })
        },
        delPerson(tag, id) {
            delLablePerson([
                {
                    id,
                },
            ])
                .then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$message.success('删除成功')
                        const tagIndex = tag.persons.findIndex((j) => j.id === tag.id)
                        tag.persons.splice(tagIndex, 1)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getTags() {
            documentLablePage(this.queryParam).then((res) => {
                if (res.code === 200) {
                    this.tags = res.data.rows.map((j) => ({ ...j, unfold: false, persons: 0 }))
                    console.log(this.tags)
                } else {
                    res.message && this.$message.warning(res.message)
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.mobileLabelManage {
    .tags {
        margin-bottom: 1px;
    }
    .tagContainer {
        padding: 0 16px;
    }
    .noPersonTip {
        padding: 0 16px;
    }
    .footer {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 45px;
        text-align: center;
        .addLabel {
            margin-right: 28px;
        }
    }
}
</style>
